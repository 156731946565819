import { createContext, ReactNode, useEffect, useState } from 'react';

import { RedlineData } from '@/common/types';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcClient } from '@/utils/trpc';

interface IRedlineContext {
  selectedRedline?: RedlineData;
  setSelectedRedline: (redline?: RedlineData) => void;
  comparingFiles: string[];
  setComparingFiles: (files: string[]) => void;
  focusedFiles: string[];
  setFocusedFiles: (files: string[]) => void;
  focusedOperation: string | undefined;
  setFocusedOperation: (operation: string | undefined) => void;
  allFiles: string[];
  setAllFiles: (files: string[]) => void;
  templateFileUrl: string | undefined;
  setTemplateFileUrl: (fileUrl: string | undefined) => void;
  diff: string;
  setDiff: (diff: string) => void;
  allFileDifferences: Map<string, number> | undefined;
  setAllFileDifferences: (diff: Map<string, number>) => void;
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export const RedlineContext = createContext<IRedlineContext | undefined>(undefined);

export function RedlinesContextProvider({ children }: { children: ReactNode }) {
  const [selectedRedline, setSelectedRedline] = useState<RedlineData | undefined>();
  const [comparingFiles, setComparingFiles] = useState<string[]>([]);
  const [focusedFiles, setFocusedFiles] = useState<string[]>([]);
  const [focusedOperation, setFocusedOperation] = useState<string | undefined>(undefined);
  const [allFiles, setAllFiles] = useState<string[]>([]);
  const [templateFileUrl, setTemplateFileUrl] = useState<string | undefined>();
  const [diff, setDiff] = useState<string>('');
  const [allFileDifferences, setAllFileDifferences] = useState<Map<string, number>>(new Map());
  const [loading, setLoading] = useState(false);
  const { matter } = useOverview();
  useEffect(() => {
    if (!selectedRedline) {
      return;
    }
    const defaultFileName = comparingFiles[0] || selectedRedline.redlineFiles[0]?.file?.name;
    const initialTemplateFile =
      templateFileUrl ||
      selectedRedline.redlineFiles.find((file) => file?.file?.name === defaultFileName)?.file
        ?.pdfUrl ||
      '';
    if (!templateFileUrl) {
      setTemplateFileUrl(initialTemplateFile);
    }
    if (allFiles.length === 0) {
      const newComparingFiles = selectedRedline.redlineFiles
        .filter((file) => file.file?.pdfUrl || '' !== initialTemplateFile)
        .map((file) => file.file?.name || '');
      const initialTemplateFileName = selectedRedline.redlineFiles
        .filter((file) => file.file?.pdfUrl || '' === initialTemplateFile)
        .map((file) => file.file?.name || '')[0];
      setAllFiles([initialTemplateFileName, ...newComparingFiles]);
      setComparingFiles(newComparingFiles);
    }
  }, [templateFileUrl, allFiles, selectedRedline, comparingFiles]);

  // Effect for fetching diffs
  useEffect(() => {
    const redlineName = selectedRedline?.name;
    if (!matter || !redlineName || (!templateFileUrl && !comparingFiles.length)) return;

    const fetchDiffs = async () => {
      setLoading(true);
      try {
        const getDiffResponse = await trpcClient.redline.getMergedSplices.query({
          redlineIdentifier: {
            matterNumber: matter.number,
            name: redlineName,
            clientNumber: matter.client.number,
            clientMatterId: matter.id,
          },
          clientMatterId: matter.id,
          templateFileUrl: templateFileUrl || '',
          fileNames: [templateFileUrl || '', ...comparingFiles],
        });
        const getAllFileDifferences = await trpcClient.redline.getDistanceMap.query({
          redlineIdentifier: {
            matterNumber: matter.number,
            name: redlineName,
            clientNumber: matter.client.number,
            clientMatterId: matter.id,
          },
          clientMatterId: matter.id,
          templateFileUrl: templateFileUrl || '',
          fileNames: [templateFileUrl || '', ...allFiles],
        });
        // Convert the received object back to a Map
        const distanceMap = new Map(Object.entries(getAllFileDifferences));
        setDiff(getDiffResponse);
        setAllFileDifferences(distanceMap);
      } finally {
        setLoading(false);
      }
    };

    fetchDiffs();
  }, [matter, selectedRedline?.name, comparingFiles, templateFileUrl, allFiles]);

  return (
    <RedlineContext.Provider
      value={{
        selectedRedline,
        setSelectedRedline,
        comparingFiles,
        setComparingFiles,
        setAllFiles,
        allFiles,
        templateFileUrl,
        setTemplateFileUrl,
        setFocusedFiles,
        focusedFiles,
        focusedOperation,
        setFocusedOperation,
        diff,
        setDiff,
        allFileDifferences,
        setAllFileDifferences,
        loading,
        setLoading,
      }}
    >
      {children}
    </RedlineContext.Provider>
  );
}
