import { useCallback, useEffect, useRef, useState } from 'react';

import RenameIcon from '@/assets/images/rename-icon-gray.svg';
import ExitIcon from '@/assets/images/x-button-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';
import { MissingSignatureIndicator } from '@/pages/overview/dataroom/content/common/MissingSignatureIndicator';
import { RenameListItemForm } from '@/pages/overview/dataroom/content/common/RenameListItemForm';
import { PdfViewerDropDown } from '@/pages/pdf-viewer/PdfViewerDropDown';
import { trpcReact } from '@/utils/trpc';

interface PdfHeaderProps {
  title: string;
  fileUrl: string;
  missingSignature: boolean | null | undefined;
  originalTitle?: string;
  onClose: () => void;
}

export const PdfHeader = ({
  title,
  fileUrl,
  missingSignature,
  originalTitle,
  onClose,
}: PdfHeaderProps) => {
  const [isFileRenameActive, setIsFileRenameActive] = useState(false);
  const [fileDisplayTitle, setFileDisplayTitle] = useState(title);
  const [isMissingSignatureVisible, setIsMissingSignatureVisible] = useState(missingSignature);
  const renameInputRef = useRef<HTMLInputElement>(null);
  const { matter } = useOverview();
  const { updateSignature } = useDataRoom();

  useEffect(() => {
    setFileDisplayTitle(title);
  }, [title]);

  const fileToRename = trpcReact.dataRoom.updateDataRoomFileName.useMutation();
  const updateFileName = (fileName: string, newName: string, title: string) => {
    if (newName === '') {
      newName = title;
    }
    fileToRename.mutate({
      name: fileName,
      clientNumber: matter.client.number,
      clientMatterNumber: matter.number,
      newName: newName,
      clientMatterId: matter.id,
    });
    setFileDisplayTitle(newName);
    setIsFileRenameActive(false);
  };

  const removeMissingSignatureTag = useCallback(() => {
    if (originalTitle) {
      setIsMissingSignatureVisible(false);
      updateSignature(originalTitle, false);
    }
  }, [originalTitle, updateSignature]);

  const addMissingSignatureTag = useCallback(() => {
    if (originalTitle) {
      setIsMissingSignatureVisible(true);
      updateSignature(originalTitle, true);
    }
  }, [originalTitle, updateSignature]);

  const onDownloadClick = () => {
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = title;
        alink.click();
      });
    });
  };

  return (
    <div
      className={`${
        isFileRenameActive ? 'bg-container-hover' : 'bg-black'
      } relative flex h-[70px] w-full items-center justify-center gap-4 border-b border-b-light-border p-4 text-center text-marveri-white`}
    >
      {isFileRenameActive && originalTitle ? (
        <div className="w-[70%]">
          <RenameListItemForm
            onClose={() => setIsFileRenameActive(false)}
            onSubmit={(newName) => updateFileName(originalTitle, newName, title)}
            currentName={fileDisplayTitle}
            renameInputRef={renameInputRef}
          />
        </div>
      ) : (
        <span className="flex break-words text-left text-[12px]">{fileDisplayTitle}</span>
      )}

      {originalTitle && !isFileRenameActive && (
        <MarveriIcon
          icon={RenameIcon}
          onClick={() => setIsFileRenameActive(true)}
          iconStyle="shrink-0 z-20"
          iconType="list-main"
        />
      )}
      {isMissingSignatureVisible && originalTitle && !isFileRenameActive && (
        <MissingSignatureIndicator fileName={originalTitle} onClick={removeMissingSignatureTag} />
      )}
      <div className="absolute right-10 top-[30%] flex gap-4">
        <PdfViewerDropDown
          onMissingSignatureClick={addMissingSignatureTag}
          onDownloadClick={onDownloadClick}
          isMissingSignatureVisible={isMissingSignatureVisible}
        />
        <MarveriIcon
          icon={ExitIcon}
          iconStyle={'h-[20px] w-[20px]'}
          iconType="other"
          onClick={onClose}
        />
      </div>
    </div>
  );
};
