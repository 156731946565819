import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import DoneIcon from '@/assets/icons/cc-done-icon.svg';
import InReviewIcon from '@/assets/icons/cc-review-icon.svg';
import TodoIcon from '@/assets/icons/cc-todo-icon.svg';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcClient } from '@/utils/trpc';

interface MaClauseStatusDropDownProps {
  status: string;
  sourceDocument: string;
}

export const MaClauseStatusDropDown = ({ status, sourceDocument }: MaClauseStatusDropDownProps) => {
  const {
    matter: {
      number: matterNumber,
      id: clientMatterId,
      client: { number: clientNumber },
    },
  } = useOverview();
  const checkStatusIcon = (status: string) => {
    if (status === 'TODO') return TodoIcon;
    if (status === 'IN_REVIEW') return InReviewIcon;
    if (status === 'DONE') return DoneIcon;
  };

  const statusIconList = [
    { icon: TodoIcon, title: 'To Do', identifier: 'TODO' },
    { icon: InReviewIcon, title: 'In Review', identifier: 'IN_REVIEW' },
    { icon: DoneIcon, title: 'Done', identifier: 'DONE' },
  ];

  const updateStatus = (selectedStatus: string) => {
    trpcClient.dataRoom.updateMAClausesStatus.mutate({
      clientNumber: clientNumber,
      clientMatterNumber: matterNumber,
      fileName: sourceDocument,
      status: selectedStatus,
      clientMatterId: clientMatterId,
    });
  };

  return (
    <Menu as="div">
      {({ open }) => (
        <div className="relative flex justify-center">
          <MenuButton className={`rounded-[4px] p-[2px] ${open ? 'bg-N700' : 'hover:bg-N700'}`}>
            <img src={checkStatusIcon(status)} className="" />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute left-[44px] top-0 z-10 w-[160px] rounded-[8px] border border-N700 bg-[#121112]">
              <h2 className="border-b border-b-N700 p-[10px] text-[12px] font-medium">
                Change status...
              </h2>
              {statusIconList.map((status, index) => (
                <div key={index}>
                  <MenuItem
                    as="div"
                    className={`${
                      index === statusIconList.length - 1 && 'rounded-b-[8px]'
                    } flex cursor-pointer gap-2 px-[12px] py-[8px] hover:bg-[#292829]`}
                    onClick={() => updateStatus(status.identifier)}
                  >
                    <img src={status.icon} />
                    <span>{status.title}</span>
                  </MenuItem>
                </div>
              ))}
            </MenuItems>
          </Transition>
        </div>
      )}
    </Menu>
  );
};
